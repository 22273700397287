import React from "react";

import SankeyDiagramParent from "./components/SankeyDiagramParent";
import SankeyForm from "./components/SankeyForm";

import gaLogo from "./assets/ga-logo.jpg";

const queryOptions = [
    {
        "name": "Floods Provenance",
        "value": "default"
    },
    {
        "name": "Floods Blocks",
        "value": "option2"
    },
    {
        "name": "Used Generated",
        "value": "usedgenerated"
    }
];

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            showLinkClasses: false,
            showTimeStamps: false,
            query: "default"
        };
    }

    handleShowLinkClassesChange = () => {
        this.setState({ showLinkClasses: !this.state.showLinkClasses });
    }

    handleShowTimeStampsChange = () => {
        this.setState({ showTimeStamps: !this.state.showTimeStamps });
    }

    handleQueryChange = (q) => {
        this.setState({ query: q });
    }

    render() {
        return (
            <div id="container">
                <header>
                    <div id="header-content">
                        <div id="header-content-left">
                            <a href="https://www.ga.gov.au/home">
                                <img src={gaLogo} alt="GA Logo" />
                            </a>
                        </div>
                        <div id="header-content-right">
                            <h1>Applying geoscience to Australia's most important challenges</h1>
                        </div>
                    </div>
                </header>
                <main>
                    <div id="main-content">
                        <h1>Provenance Sankey Diagram</h1>
                        <div>
                            This Sankey Diagram shows different views of provenance data; for example the flow of information between Activities, Entities, and Blocks which constitute Workflows.
                        </div>
                        <SankeyForm
                            showLinkClasses={this.state.showLinkClasses}
                            handleShowLinkClassesChange={this.handleShowLinkClassesChange}
                            showTimeStamps={this.state.showTimeStamps}
                            handleShowTimeStampsChange={this.handleShowTimeStampsChange}
                            queryOptions={queryOptions}
                            query={this.state.query}
                            handleQueryChange={this.handleQueryChange}
                        />
                        <SankeyDiagramParent
                            showLinkClasses={this.state.showLinkClasses}
                            showTimeStamps={this.state.showTimeStamps}
                            query={this.state.query}
                        />
                    </div>
                </main>
                <footer>
                    <div id="footer-content">
                        <div id="footer-content-top">
                            <a href="https://www.ga.gov.au/copyright">Copyright</a>
                            <a href="https://www.ga.gov.au/privacy">Privacy</a>
                            <a href="https://www.ga.gov.au/accessibility">Accessibility</a>
                            <a href="https://www.ga.gov.au/sitemap">Sitemap</a>
                            <a href="https://www.ga.gov.au/ips">Information Publication Scheme</a>
                            <a href="https://www.ga.gov.au/ips/foi">Freedom of Information</a>
                            <a href="https://www.ga.gov.au/contact-us">Contact Us</a>
                        </div>
                        <div id="footer-content-bottom">
                            We acknowledge the Traditional Owners of the country where we work throughout Australia. We acknowledge Aboriginal and Torres Strait Islander people's continuing connection to land, sea and community. We pay our respects to their elders past and present and emerging.
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
