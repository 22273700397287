import React from "react";
import { sankey } from "d3-sankey";

import SankeyNode from "./SankeyNode";
import SankeyLink from "./SankeyLink";

class SankeyDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graph: sankey()
                .nodeId(d => d.ID) // custom ID key for links to reference (default is node index)
                .nodeWidth(20)
                .nodePadding(20)
                .extent([[1, 1], [props.width - 1, props.height - 5]])(props.data)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                graph: sankey()
                    .nodeId(d => d.ID) // custom ID key for links to reference (default is node index)
                    .nodeWidth(20)
                    .nodePadding(20)
                    .extent([[1, 1], [this.props.width - 1, this.props.height - 5]])(this.props.data)
            })
        }
    }

    render() {
        return (
            <svg style={{ mixBlendMode: "multiply", width: "100%", height: "100%" }}>
                {this.state.graph.nodes.map(node => {
                    return <SankeyNode
                        {...node}
                        color={node.prov_class === "Entity" ? "#fffe9a" : "#d3d2ff"}
                        // key={node.Name}
                        windowWidth={this.props.width}
                    />
                })}
                {this.state.graph.links.map((link, index) => {
                    return <SankeyLink
                        link={link}
                        color="grey"
                        showLinkClasses={this.props.showLinkClasses}
                    // key={index}
                    />
                })}
            </svg>
        );
    }
};

export default SankeyDiagram;