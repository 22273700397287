import React from "react";
import { sankeyLinkHorizontal } from "d3-sankey";

class SankeyLink extends React.Component {
    render() {
        return (
            <>
                <path
                    d={sankeyLinkHorizontal()(this.props.link)}
                    style={{
                        fill: "none",
                        strokeOpacity: ".3",
                        stroke: this.props.color,
                        strokeWidth: Math.max(1, this.props.link.width)
                    }}
                    className="link"
                    id={`link-${this.props.link.index}`}
                >
                    <title>{this.props.link.link_class}</title>
                </path>
                {this.props.showLinkClasses && <text>
                    <textPath
                        href={`#link-${this.props.link.index}`}
                        startOffset="50%"
                        textAnchor="middle"
                        fill="#808080"
                        fontSize="12px"
                    >
                        {this.props.link.link_class}
                    </textPath>
                </text>}
            </>
        );
    }
};

export default SankeyLink;