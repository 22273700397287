import React from "react";

class SankeyNode extends React.Component {
    render() {
        return (
            <g className="node" style={{ cursor: "pointer" }}>
                <rect
                    x={this.props.x0}
                    y={this.props.y0}
                    width={this.props.x1 - this.props.x0}
                    height={this.props.y1 - this.props.y0}
                    fill={this.props.color}
                    style={{ stroke: "black" }}
                >
                    <title>
                        Name: {this.props.Name}{"\n"}
                        Timestamp: {this.props.timestamp}{"\n"}
                        Value: {this.props.data_value}
                    </title>
                </rect>
                {Math.abs(this.props.x0 - this.props.windowWidth) < 50
                    ? <text
                        x={this.props.x0 - (this.props.x1 - this.props.x0) + 6}
                        y={this.props.y1 - (this.props.y1 - this.props.y0) / 2}
                        dy=".35em"
                        textAnchor="end"
                    >
                        {this.props.Name}
                    </text>
                    : <text
                        x={this.props.x0 + (this.props.x1 - this.props.x0) + 6}
                        y={this.props.y1 - (this.props.y1 - this.props.y0) / 2}
                        dy=".35em"
                    >
                        {this.props.Name}
                    </text>
                }
            </g>
        );
    }
};

export default SankeyNode;