import React from "react";

class SankeyForm extends React.Component {
    render() {
        return (
            <div id="form-content">
                <div id="form">
                    <div className="form-col">
                        <div className="form-item">
                            <label htmlFor="query-select">Query</label>
                            <select
                                name="query-select"
                                id="query-select"
                                value={this.props.query}
                                onChange={e => this.props.handleQueryChange(e.target.value)}
                            >
                                {this.props.queryOptions.map(option => {
                                    return (
                                        <option value={option.value} key={option.value}>{option.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-col">
                        <div className="form-item">
                            <input
                                type="checkbox"
                                name="show-link-classes"
                                id="show-link-classes"
                                checked={this.props.showLinkClasses}
                                onChange={() => this.props.handleShowLinkClassesChange()}
                            />
                            <label htmlFor="show-link-classes">Show link classes</label>
                        </div>
                        <div className="form-item">
                            <input
                                type="checkbox"
                                name="show-timestamps"
                                id="show-timestamps"
                                checked={this.props.showTimeStamps}
                                onChange={() => this.props.handleShowTimeStampsChange()}
                            />
                            <label htmlFor="show-timestamps">Show timestamps</label>
                        </div>
                    </div>
                    <div className="form-col">
                        <h3 style={{ marginBottom: "0" }}>Legend</h3>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                                <h4>Nodes</h4>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ backgroundColor: "#fffe9a", border: "1px black solid", height: "40px", width: "20px" }}></div>
                                        <span style={{ marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>Entity</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
                                        <div style={{ backgroundColor: "#d3d2ff", border: "1px black solid", height: "40px", width: "20px" }}></div>
                                        <span style={{ marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>Activity</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginLeft: "30px" }}>
                                <h4>Links</h4>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ backgroundColor: "lightgrey", height: "40px", width: "60px" }}></div>
                                    <span style={{ marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>Link</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SankeyForm;
