import React from "react";
import { DataborgClient } from "@databorg/client";

import SankeyDiagram from "./SankeyDiagram";

// create new client
const client = new DataborgClient({
    queryEndpoint: "http://digital-atlas-lb-1137864764.ap-southeast-2.elb.amazonaws.com:3200/fsdf",
    prefixes: {
        rdfs: "http://www.w3.org/2000/01/rdf-schema#",
        pwfs: "https://data.surroundaustralia.com/dataset/provworkflows/",
        prov: "http://www.w3.org/ns/prov#",
        provwf: "https://data.surroundaustralia.com/def/provworkflow/",
        hawkesbury: "https://linked.data.gov.au/dataset/floods/hawkesbury-2021",
        block: "https://surroundaustralia/blocks/io/",
        dcterms: "http://purl.org/dc/terms/",
    },
});


class SankeyDiagramParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            width: 0,
            height: 0
        };
        this.svgRef = React.createRef();
    }

    getNodes = async () => {
        if (this.props.query === "option2") {
            return client.query({
                query: `SELECT DISTINCT ?iri ?id ?lbl ?class 
     WHERE {
      BIND (hawkesbury: AS ?entity)
      VALUES ?knownClass { "Entity" "Activity" }

      {
      BIND (hawkesbury: AS ?iri)
      ?entity rdfs:label ?lbl ; a ?class_ .
      {?entity a ?knownEntityClass}
      BIND (STRAFTER(STR(?entity), "https://linked.data.gov.au/dataset/") AS ?id)
      BIND (STRAFTER(STR(?class_), STR(prov:)) AS ?class)
  }
      UNION
      {
      ?versionedEntity prov:wasRevisionOf ?entity .

      BIND (?versionedEntity AS ?iri)
      ?versionedEntity rdfs:label ?lbl ; a ?class_ .
      {?versionedEntity a ?knownEntityClass}
      BIND (REPLACE(STRAFTER(STR(?versionedEntity), CONCAT(STR(?entity), "/")), "/", "-") AS ?id)
      BIND (STRAFTER(STR(?class_), STR(prov:)) AS ?class)
  }
      UNION
      {
      ?workflow prov:generated ?versionedEntity .

      BIND (?workflow AS ?iri)
      ?workflow rdfs:label ?lbl ; a ?class_ .
      {?workflow a ?knownActivityClass}
      BIND (STRAFTER(STR(?workflow), "https://data.surroundaustralia.com/dataset/provworkflows/") AS ?id)
      BIND (STRAFTER(STR(?class_), STR(prov:)) AS ?class)
  }

      ?workflow prov:endedAtTime ?eat .

      FILTER (?class IN (?knownClass))
  }
      ORDER BY DESC(?eat)`,
                options: {
                    parsing: {
                        type: "custom",
                        rowProcessor: (row, results) => {
                            results.push({
                                "ID": row.iri.value,
                                "Name": row.lbl.value,
                                "prov_class": row.class.value,
                                "uri": row.id.value,
                                // "data_value": row.data_value.value,
                            });
                        },
                    },
                },
            });
        }
        else if (this.props.query === "default") {
            return client.query({
                query: `SELECT DISTINCT ?iri ?id ?lbl ?class ?data_value
                 WHERE {
                  BIND (hawkesbury: AS ?entity)
                  VALUES ?knownClass { prov:Activity prov:Entity provwf:Block }
  				VALUES ?workflow {pwfs:a7a99e2f-0971-11ed-bee3-8b1d5e0c55d6}
  
                  {
    			  ?workflow provwf:hadBlock ?block .
    			  ?block a ?class_ .
    			  OPTIONAL {?block rdfs:label ?label .}
                  BIND(COALESCE(?label, "missing label") AS ?lbl)
    			  BIND("" AS ?data_value)
                  BIND(?block as ?iri)
                  BIND (STRAFTER(STR(?block), "https://data.surroundaustralia.com/dataset/provworkflows/") AS ?id)
                  BIND (STRAFTER(STR(?class_), STR(provwf:)) AS ?class)
                  }
  
  					UNION
  
                  {
    			# block's used entities
    			?workflow provwf:hadBlock ?block .
    			?block prov:used ?iri .
                ?iri rdfs:label ?lbl ; 
                     a ?class_ ;
                     prov:value ?data_value
    			BIND (STRAFTER(STR(?iri), STR(pwfs:)) AS ?id)
                BIND (STRAFTER(STR(?class_), STR(prov:)) AS ?class)
            		}
  
    					UNION
  
                  {
    			# block's generated entities
    			?workflow provwf:hadBlock ?block .
    			?block prov:generated ?iri .
                ?iri rdfs:label ?lbl ; 
                     a ?class_ ;
                     prov:value ?data_value
    			BIND (STRAFTER(STR(?iri), STR(pwfs:)) AS ?id)
                BIND (STRAFTER(STR(?class_), STR(prov:)) AS ?class)
            		}
  
                  FILTER (?class_ IN (?knownClass))
              }
                  ORDER BY DESC(?eat)`,
                options: {
                    parsing: {
                        type: "custom",
                        rowProcessor: (row, results) => {
                            results.push({
                                "ID": row.iri.value,
                                "Name": row.lbl.value,
                                "prov_class": row.class.value,
                                "uri": row.id.value,
                                "data_value": row.data_value.value,
                            });
                        },
                    },
                },
            });
        }
        else if (this.props.query === "usedgenerated") {
            return client.query({
                query: `SELECT DISTINCT ?iri ?id ?lbl ?class ?data_value ?timestamp
                        WHERE {
                          {
                            ?block a block:python-read-postgres ;
                          prov:generated ?iri .
                            ?iri a ?class_ ;
                          prov:value ?data_value ;
                          dcterms:created ?timestamp ;
                          rdfs:label ?lbl ; 
                          BIND (STRAFTER(STR(?iri), "https://data.surroundaustralia.com/dataset/provworkflows/") AS ?id)
                            BIND (STRAFTER(STR(?class_), STR(prov:)) AS ?class)
                          }
                          UNION
                          {
                            ?block a block:python-read-postgres ;
                          prov:used ?iri .
                            ?iri a ?class_ ;
                          rdfs:label ?lbl ; 
                            prov:value ?data_value ;
                        dcterms:created ?timestamp ;
                          BIND (STRAFTER(STR(?iri), "https://data.surroundaustralia.com/dataset/provworkflows/") AS ?id)
                            BIND ("Block" AS ?class)
                          }
                          UNION
                          {
                            BIND(block:python-read-postgres AS ?iri)
                            BIND("Python Read Postgres Block" AS ?lbl)
                            BIND ("python-read-postgres" AS ?id)
                            BIND ("Block" AS ?class)
                            BIND ("N/A" AS ?data_value)
                            BIND ("N/A" AS ?timestamp)
                          }
                        }`,
                options: {
                    parsing: {
                        type: "custom",
                        rowProcessor: (row, results) => {
                            results.push({
                                "ID": row.iri.value,
                                "Name": row.lbl.value,
                                "prov_class": row.class.value,
                                "uri": row.id.value,
                                "data_value": row.data_value.value,
                                "timestamp": row.timestamp.value,
                            });
                        },
                    },
                },
            });
        }
    }

    getLinks = async () => {
        if (this.props.query === "option2") {
            return client.query({
                query: `SELECT DISTINCT ?source ?target ?linkClass 
                        WHERE {
                        BIND (hawkesbury: AS ?entity)
                    
                        {
                          ?versionedEntity prov:wasRevisionOf ?entity .
                          BIND (?versionedEntity AS ?source)
                          BIND (hawkesbury: AS ?target)
                          BIND (prov:wasRevisionOf AS ?linkClass)
                        }
                        UNION
                        {
                          ?workflow prov:generated ?versionedEntity .
                          ?versionedEntity prov:wasRevisionOf ?entity .
                          BIND (?workflow AS ?source)
                          BIND (?versionedEntity AS ?target)
                          BIND (prov:generated AS ?linkClass)
                        }
                      }
                      ORDER BY DESC(?eat)`,
                options: {
                    parsing: {
                        type: "custom",
                        rowProcessor: (row, results) => {
                            results.push({
                                "source": row.source.value,
                                "target": row.target.value,
                                "value": 1,
                                "link_class": row.linkClass.value,
                            });
                        },
                    },
                },
            });
        }
       else if (this.props.query === "default") {
            return client.query({
                query: `SELECT DISTINCT ?source ?target ?linkClass 
WHERE {
  VALUES ?workflow {pwfs:a7a99e2f-0971-11ed-bee3-8b1d5e0c55d6}
  
    {
    ?workflow provwf:hadBlock ?block .
    ?block prov:used ?entity ;
           a provwf:Block .
    BIND (?block AS ?target)
    BIND (?entity AS ?source)
    BIND (prov:used AS ?linkClass)
  }
  UNION
      {
    ?workflow provwf:hadBlock ?block .
    ?block prov:generated ?entity ;
           a provwf:Block .
    BIND (?block AS ?source)
    BIND (?entity AS ?target)
    BIND (prov:generated AS ?linkClass)
  }
}
ORDER BY DESC(?eat)`,
                options: {
                    parsing: {
                        type: "custom",
                        rowProcessor: (row, results) => {
                            results.push({
                                "source": row.source.value,
                                "target": row.target.value,
                                "value": 1,
                                "link_class": row.linkClass.value,
                            });
                        },
                    },
                },
            });
        }
              else if (this.props.query === "usedgenerated") {
            return client.query({
                query: `SELECT DISTINCT ?source ?target ?linkClass 
                        WHERE {
                          {
                            BIND(block:python-read-postgres AS ?source)
                            ?instance_of_source a ?source ;
                          prov:generated ?target .
                            ?target a ?class_ ;
                          BIND (prov:generated AS ?linkClass)
                          }
                          UNION
                          {
                            BIND(block:python-read-postgres AS ?target)
                            ?instance_of_target a ?target ;
                          prov:used ?source .
                            ?source a ?class_ ;
                          BIND (prov:used AS ?linkClass)
                          }
                        }`,
                options: {
                    parsing: {
                        type: "custom",
                        rowProcessor: (row, results) => {
                            results.push({
                                "source": row.source.value,
                                "target": row.target.value,
                                "value": 1,
                                "link_class": row.linkClass.value,
                            });
                        },
                    },
                },
            });
        }
    }

    sparqlQuery = async () => {
        const [result_nodes, result_links] = await Promise.all([this.getNodes(), this.getLinks()]);
        this.setState({
            data: {
                "nodes": result_nodes.response,
                "links": result_links.response,
            }
        });
    }

    async componentDidMount() {
        await this.sparqlQuery();
        // this.setState({ data: jsonData });
        this.measureSVG();
        window.addEventListener("resize", this.measureSVG);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query) {
            await this.sparqlQuery();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.measureSVG);
    }

    measureSVG = () => {
        const { width, height } = this.svgRef.current.getBoundingClientRect();

        this.setState({
            width,
            height
        });
    };

    render() {
        return (
            <div className="sankey-diagram-svg">
                {/* <h1>Sankey Diagram</h1> */}
                <svg width="100%" height="500" ref={this.svgRef}>
                    {this.state.data && <SankeyDiagram data={this.state.data} width={this.state.width} height={this.state.height} showLinkClasses={this.props.showLinkClasses} />}
                </svg>
            </div>
        );
    }
};

export default SankeyDiagramParent;